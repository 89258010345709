import React from 'react'
import News from '../components/News'
import SEO from '../components/seo'
import { useArticlesData } from '../hooks/useArticlesData'
import { useTranslations } from '../hooks/use-translations'
import SgnxImageSeo from '../assets/images/seo/sgnx/articles.png'

const ArticlesPage: React.FC = () => {
  const { t } = useTranslations()
  const title = t('Articles')
  const articles = useArticlesData()
  return (
    <>
      <SEO
        title={title}
        image={SgnxImageSeo}
        description="Discover a diverse range of insightful articles on technology, business, lifestyle, and more at signax.io. Stay informed and inspired with us!"
      />
      <News title={title} items={articles} />
    </>
  )
}

export default ArticlesPage
